<template>
    <div class="loading" data-cy="loading-wrap">
        <span class="loader"></span>
    </div>
</template>

<script>
export default {
    props: ['opacity', 'color', 'bgColor']
}
</script>

<style scoped>
.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #63cbe6;
    animation: prixClipFix 2s linear infinite ;
}
.loader::after{
    inset: 8px;
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: #1d4186;
}
@keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
}
@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}
</style>