import BaseButton from '../components/base/BaseButton'
import BaseCard from '../components/base/BaseCard'
import BaseInput from '../components/base/BaseInput'
import Layout from "../components/general/Layout"
import Modal from '../components/general/Modal'
import Loading from '../components/general/Loading'
import LayoutStore from "../components/general/LayoutStore"
import DataTable from "../components/general/DataTable"

export default function(Vue) {
    Vue.component("BaseButton" , BaseButton)
    Vue.component("BaseCard" , BaseCard)
    Vue.component("BaseInput" , BaseInput)
    Vue.component("Layout" , Layout)
    Vue.component("Modal" , Modal)
    Vue.component("Loading" , Loading)
    Vue.component("LayoutStore" , LayoutStore)
    Vue.component("DataTable",DataTable)
}
