<template>
    <div :class="classWrap">
        <button
            :style="{'background-color': backgroundColor, 'border-color': borderColor, 'color': color}"
            :class="classes"
            :disabled="disabled"
            @click="onClick"
        >
            {{ label }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'base-button',
    props: {
        label: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: '#ffffff',
        },
        borderColor: {
            type: String,
            default: '#ffffff',
        },
        color: {
            type: String,
            default: '#064e8c',
        },
        outline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'button': true,
                'button-outline': this.outline,
                'button-shadow': this.shadow,
            };
        },
        classWrap() {
            return {
                'button-wrap': true,
                'button-wrap-sm': this.size === 'sm',
            }
        }
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
}
</script>

<style scoped>
.button-wrap {
    /* width: 174px; */
    height: 52px !important;
    border-radius: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(2,75,138);
    background: linear-gradient(90deg, rgba(2,75,138,1) 0%, rgba(126,189,243,1) 48%, rgba(126,66,209,1) 100%);
}
.button {
    font-family: 'DB Heavent', sans-serif;
    font-weight: 700;
    font-size: 30px;
    border: none;
    border-radius: 12px !important;
    color: #064e8c;
    background-color: #ffffff;
    height: 40px;
    min-height: 40px;
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    text-indent: 0;
    text-transform: none;
}
.button:focus {
    box-shadow: none;
    outline: none
}
.button:disabled {
    background-color: #E8E8E8 !important;
    border: 2px solid #E8E8E8 !important;
    color: #9D9EA4 !important;
}
.button:disabled.button-shadow {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.button-outline {
    border: 2px solid #E14826 !important;
    background-color: transparent !important;
    color: #E14826 !important;
}
.button.button-shadow {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 350px) {
    .button{
        font-size: 22px;
    }
}
@media only screen and (min-width: 351px) and (max-width: 370px) {
    .button{
        font-size: 24px;
    }
}
@media only screen and (min-width: 371px) and (max-width: 384px) {
    .button{
        font-size: 26px;
    }
}
@media only screen and (min-width: 385px) and (max-width: 400px) {
    .button{
        font-size: 28px;
    }
}
</style>
