<template>
    <div :class="classes" >
        <div class="inner-bg">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        classes() {
            return {
                'content': true,
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.content {
	position: relative;
    min-height: calc(var(--vh, 1vh) * 100);
    box-shadow: 0px 0px 10px grey;
    background-color: #63cbe6;
    & .inner-bg{
        background-image: url("@/assets/images/BG.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

}
@media only screen and (max-width: 600px) {}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {
    .content{
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
}
</style>