import Vue from "vue";
import VueCookies from "vue-cookies";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import ComponentRegistration from "./utility/components-registration";

import "@/styles/index.css";

const optionsToast = {
	timeout: 2000,
	closeOnClick: true,
	pauseOnHover: true,
};

Vue.config.productionTip = false;
ComponentRegistration(Vue);
Vue.use(VueCookies);
Vue.use(Toast, optionsToast);

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

async function runner() {
	new Vue({
		router,
		store,
		vuetify,
		render: (h) => h(App),
	}).$mount("#app");
}

console.log(process.env.VUE_APP_ENV);
console.log("0.0.2 clear cookie");
const cookies = document.cookie.split(";");

for (let i = 0; i < cookies.length; i++) {
	const cookie = cookies[i];
	const eqPos = cookie.indexOf("=");
	const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
	document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
}
// lineLogin(process.env.VUE_APP_LINE_LIFF_ID)
lineLogin("1656881865-JeNww6kr");
function lineLogin(liff_id) {
	console.log(process.env.VUE_APP_LINE_LIFF_ID);
	if (window.location.hostname === "localhost") {
		const userData = {
			userId: "U2fc40eee11321ad0e2aa5b8d33176ad0",
			email: "aewtippawanm@gmail.com",
			displayName: "เรียกหนูว่าอิ๋ว🐹",
			pictureUrl:
				"https://profile.line-scdn.net/0hMuLcsjHZElphGgXG20JsJRFKETBCa0tISisObFASRWldLQcLRX8NaQZKTWMJfVYLGCxeOgMaRWxtCWU8f0zubmYqTG1YLFwITHtcuA",
			line_liff_token:
				"eyJraWQiOiI5YjExMTJiMDk4ZjMxODRjNmU4M2ZjM2QxYmU4ZmMyMTdkYjFlYjIwNzU2YTE1NmNiNjNjZThjZTllMjdkZmQyIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2FjY2Vzcy5saW5lLm1lIiwic3ViIjoiVTVlOGI5YzJkN2Q1YzhmNzRmOTRiYWU1NTA1YjhmY2QzIiwiYXVkIjoiMTY1NzQyODMzOSIsImV4cCI6MTY3NDU1NjY5MywiaWF0IjoxNjc0NTUzMDkzLCJhbXIiOlsibGluZXNzbyJdLCJuYW1lIjoi4LmA4Lij4Li14Lii4LiB4Lir4LiZ4Li54Lin4LmI4Liy4Lit4Li04LmL4Lin8J-QuSIsInBpY3R1cmUiOiJodHRwczovL3Byb2ZpbGUubGluZS1zY2RuLm5ldC8waE11TGNFNFBNRWxweEdnWEcyMEp0RFUxZkhEY0dOQlFTQ1hzS2J3UWVSR05mSzFKZVNuUmViQUZJSEd0VmZ3SVBTaWtOUDFKTlRHTmEifQ.vInUhXUF-0Gylm-ID67eAfAk4QXBwe5-bGlk-vt6a1r94XpVvz7QHMh4eum6dH4RYVagg_Z0_Tghd1pMsh_Lzw",
		};
		if (userData) {
			$cookies.set("LINE_ALCON_LIFF_DATA", JSON.stringify(userData));
		}
		runner();
	} else {
		try {
			const path = location.href.split(location.origin);
			const path2 = path[1].split("/?liff.state=");
			if (
				decodeURIComponent(path2[1]) !== undefined &&
				decodeURIComponent(path2[1]) !== null &&
				decodeURIComponent(path2[1]) !== "undefined" &&
				decodeURIComponent(path2[1]) !== "null"
			) {
				$cookies.set("LINE_LIFF_ORIGIN_PATH", decodeURIComponent(path2[1]));
				console.log(decodeURIComponent(path2[1]));
			}

			liff.init({ liffId: liff_id })
				.then(async () => {
					if (liff.isLoggedIn()) {
						const profile = await liff.getProfile();
						profile.line_liff_token = liff.getIDToken();
						console.log("Check Profile Debug:", profile);
						if (profile) {
							$cookies.set("LINE_ALCON_LIFF_DATA", JSON.stringify(profile));
						}
						runner();
					} else {
						$cookies.remove("LINE_ALCON_LIFF_DATA");
						liff.login();
					}
				})
				.catch((err) => {
					runner();
					$cookies.remove("LINE_ALCON_LIFF_DATA");
					console.log(err);
					// alert("liff.init catch",err)

					router.push("/error-liff?page=app&message=errliff");
				});
		} catch (error) {
			runner();
			$cookies.remove("LINE_ALCON_LIFF_DATA");
			console.log("try->", err);
			router.push("/error-liff?page=app&message=" + err);
		}
	}
}
