import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/Index.vue')
    },
    {
        path: '/questionnaire01',
        name: 'Questionnaire 01',
        component: () => import('../views/Questionnaire01.vue')
    },
    {
        path: '/questionnaire02',
        name: 'Questionnaire 02',
        component: () => import('../views/Questionnaire02.vue')
    },
    {
        path: '/questionnaire03',
        name: 'Questionnaire 03',
        component: () => import('../views/Questionnaire03.vue')
    },
    {
        path: '/questionnaire04',
        name: 'Questionnaire 04',
        component: () => import('../views/Questionnaire04.vue')
    },
    {
        path: '/questionnaire05',
        name: 'Questionnaire 05',
        component: () => import('../views/Questionnaire05.vue')
    },
    {
        path: '/coupons',
        name: 'Coupons',
        component: () => import('../views/Coupons.vue')
    },
    {
        path: '/coupon',
        name: 'Coupons detail',
        component: () => import('../views/CouponDetail.vue')
    },
    {
        path: '/shops',
        name: 'Shops',
        component: () => import('../views/Shops.vue')
    },


    //Admin Router
    {
        path: '/admin',
        name: 'Welcome Page',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/Index')
    },
    {
        path: '/admin/term',
        name: 'Welcome Page',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/term/Index')
    },
    {
        path: '/admin/welcome',
        name: 'Welcome Page',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/welcome/Index')
    },
    {
        path: '/admin/use-coupon',
        name: 'Use Coupon',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/useCoupon/Index')
    },
    {
        path: '/scan-qr',
        name: 'Use Coupon',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/useCoupon/Index')
    },
    {
        path: '/admin/qr',
        name: 'qr',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/scanQR/Index')
    },
    {
        path: '/admin/history',
        name: 'qr',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/history/Index')
    },
    {
        path: '/admin/finish',
        name: 'qr',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/finish/Index')
    },

    {
        path: '/error',
        name: 'error',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/Error.vue')
    },
    {
        path: '/error-liff',
        name: 'error liff',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/Error.vue')
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router